import { Dispatch } from '@redux/store';
import { createModel } from '@rematch/core';
import { LocationService } from '@services';
import type { LatLngType, LocationDtoType, RootModel, UserType } from '@types';
import baseEntity from './base.entity';

interface LocationEntitiesState {
  ids: { [id: number]: LocationDtoType };
}

const initialState: LocationEntitiesState = {
  ids: {},
};

export const locationEntities = createModel<RootModel>()({
  state: initialState,
  reducers: {
    ...baseEntity,
    updatePosition(state, payload: { id: number; position: LatLngType }) {
      if (payload) {
        const {
          id,
          position: { lat, lng },
        } = payload;
        state.ids[id].lat = lat;
        state.ids[id].lng = lng;
      }
    },
  },
  effects: (dispatch: Dispatch) => ({
    async findById(id, state): Promise<LocationDtoType | null> {
      const { ids = {} } = state.locationEntities;
      const item = ids[id];
      if (item && !item.simplified) {
        return item;
      }

      const { data } = await LocationService.getLocation(id);
      this.saves([data]);
      return data;
    },
    async updateDetails(payload: LocationDtoType, state) {
      const { user } = state.app;
      const uploadActions: Promise<void>[] = [];

      const newLocation: LocationDtoType = {
        ...payload,
      };
      setAudio(newLocation, user!, uploadActions);
      if (newLocation.translations && !!newLocation.translations.length) {
        newLocation.translations.forEach((translation) => {
          setAudio(translation, user!, uploadActions);
        });
      }
      await Promise.all(uploadActions);
      const { data } = await LocationService.updateLocation(newLocation);
      this.saves([data]);
    },
  }),
});

const setAudio = (data: LocationDtoType, user: UserType, uploadActions: Promise<void>[]) => {
  // TODO: wait for media migrate
  // if (data.audio) {
  //   const promise = UploaderService.uploadFiles(
  //     data.id,
  //     [data.audio],
  //     FILE_NAME_TYPE.AUDIO,
  //     user.id,
  //     'locations'
  //   ).then((result) => {
  //     convertFilesUploadDataToDto(data, 'audio', result, false);
  //   });
  //   uploadActions.push(promise);
  // }
};
