export const convertObjectToString = (params: { [key: string]: string }) => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
};

export const getSortByColumn = (sort: string): string | undefined | null => {
  return sort.substring(0, sort.indexOf(','));
};

export const getOrderSort = (sort: string): string | undefined | null => {
  return sort.substring(sort.indexOf(',') + 1, sort.length) === 'ASC' ? 'ascend' : 'descend';
};

export const matchYoutubeUrl = (url: string) => {
  const p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const match = url.match(p);
  if (match != null && match.length) {
    return !!url && match[1];
  }

  return false;
};

export const getYouTubeVideoIdFromUrl = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : undefined;
};
