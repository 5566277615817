/* eslint-disable camelcase */
import {
  getAccessToken,
  setAccessToken,
  setExpiredTime,
  setRefreshToken,
} from '@utils/storageUtils';
import type { AxiosInstance } from 'axios';
import baseAxios from 'axios';
import qs from 'query-string';

interface TokenInfo {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

interface BaseAxios extends AxiosInstance {
  setToken?: (data?: TokenInfo) => void;
}

declare global {
  interface Window {
    REACT_APP_API_HOST: string;
  }
}

export const baseURL =
  window.REACT_APP_API_HOST ||
  process.env.REACT_APP_API_HOST ||
  `${window.location.protocol}//${window.location.host}`;

const axios: BaseAxios = baseAxios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => qs.stringify(params),
});

axios.setToken = (data?: TokenInfo) => {
  let token = getAccessToken();
  if (data) {
    const { access_token, refresh_token, expires_in } = data;
    token = access_token;
    setRefreshToken(refresh_token);
    setAccessToken(token);
    setExpiredTime(expires_in);
  }
  if (token) {
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;
  }
};

const { get, post, put } = axios;
export { get, post, put, axios };
