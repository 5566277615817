import { FC } from 'react';

import './styles.scss';

type PropTypes = {
  loading?: boolean
}

const Loading: FC<PropTypes> = ({ loading = false }) => {
  return (
    loading && (
      <div className="loading">
        <div className="lds-ripple">
          <div className="icon">
            <img alt="Clik" src={`${process.env.PUBLIC_URL}/images/logo/logo.svg`} />
          </div>
          <div className="rotate"></div>
        </div>
      </div>
    )
  );
};

export default Loading;
