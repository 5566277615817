import React from 'react';
import { render } from 'react-dom';

import App from './App';

import './index.css';
import reportWebVitals from './reportWebVitals';

const AppContent = () => {
  return (
    process.env.NODE_ENV !== 'production' ? (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    ) : <App />
  );
};

render(<AppContent />, document.getElementById('root')!);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
