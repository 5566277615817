export const hasPermission = (permission: string, userPermissions: string[]): boolean => {
  if (!userPermissions || userPermissions.length <= 0 || !permission || !permission.trim()) {
    return false;
  }

  const PERMISSION = permission.trim().toUpperCase();
  for (const i in userPermissions) {
    if (userPermissions[i].trim().toUpperCase() === PERMISSION) {
      return true;
    }
  }
  return false;
};


export const toParams = (query: string) : any => {
  const q = query.replace(/^(\?|#)/, '');

  return q.split('&').reduce((values, param) => {
    const [key, value] = param.split('=');

    return { ...values, [key]: decodeURIComponent(value) };
  }, {});
};

export const toQuery = (params: any, delimiter = '&') : string => {
  const keys = Object.keys(params);

  return keys.reduce((str, key, index) => {
    let query = `${str}${key}=${params[key]}`;

    if (index < keys.length - 1) {
      query += delimiter;
    }

    return query;
  }, '');
};