import { DEFAULT_PAGINATION } from '@commons/constants';
import { Dispatch } from '@redux/store';
import { createModel } from '@rematch/core';
import {
  PageRequest,
  RootModel,
  UserTravelingCriteria,
  UserTravelingDtoType,
  UserTravelingModel,
} from '@types';
import { message } from 'antd';
import produce from 'immer';

const initialState: UserTravelingModel = {
  ids: [],
  loading: false,
  pagination: DEFAULT_PAGINATION,
  criteria: null,
  owner: null,
  createModalOpened: false,
};

export const userTraveling = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setIds(state, payload) {
      state.ids = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
    setCriteria(state, payload) {
      state.criteria = payload;
    },
    setOwner(state, payload) {
      state.owner = payload;
    },
    setCreateModalOpened(state, payload) {
      state.createModalOpened = payload;
    },
    resetState: produce((state, payload) => {
      return {
        ...payload,
      };
    }),
  },
  effects: (dispatch: Dispatch) => ({
    changeOwner(payload, state) {
      const { owner, pagination, criteria } = state.userTraveling;
      const initState = {
        ...initialState,
        pagination: {
          ...initialState.pagination,
          size: pagination.size,
        },
      };
      let newState;
      if (!owner || !payload || owner.id !== payload.id) {
        newState = { ...initState, owner: payload };
      } else {
        newState = { ...initState, pagination, criteria, owner: payload };
      }
      this.resetState(newState);
    },
    async filterAsync(payload: PageRequest & UserTravelingCriteria = {}, state) {
      this.setLoading(true);
      try {
        const req = { ...payload };
        const { owner } = state.userTraveling;
        if (owner) {
          req.travelingId = owner.id;
        }

        const data = await dispatch.userTravelingEntities.load(req);
        const { content, ...pagination } = data;

        if (content) {
          this.setIds(data.content.map((x) => x.id));
          this.setPagination(pagination);
        }
        this.setCriteria(req);
      } catch (e) {
        console.error(e);
        message.error('Failed to get user travelings');
      } finally {
        this.setLoading(false);
      }
    },
    async pagingAsync(payload: PageRequest, state) {
      const { criteria } = state.traveling;
      const request = {
        ...criteria,
        ...payload,
      };
      await this.filterAsync(request);
    },
    async reloadList(payload, state) {
      const { criteria, pagination } = state.traveling;
      await this.filterAsync({ ...pagination, ...criteria });
    },
    async createAsync(userId: number, state) {
      this.setLoading(true);
      try {
        const {
          owner,
          pagination: { size = DEFAULT_PAGINATION.size },
        } = state.userTraveling;
        await dispatch.userTravelingEntities.create({
          travelingId: owner!.id,
          userId,
        });
        await this.filterAsync({ page: 0, size });
        return true;
      } catch (e) {
        console.error(e);
        message.error('Failed to share traveling for user' + userId);
        return false;
      } finally {
        this.setLoading(false);
      }
    },
    async deleteAsync(item: UserTravelingDtoType) {
      this.setLoading(true);
      try {
        await dispatch.userTravelingEntities.delete(item.id);
        message.warning(`Un-shared user ${item.user?.email}`);
        await this.reloadList();
      } catch (e) {
        console.error(e);
        message.error('Failed to un-share user ' + item.user?.email);
      } finally {
        this.setLoading(false);
      }
    },
  }),
});
