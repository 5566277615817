import { FormProps } from 'antd/lib/form/Form';

export const formLayout: FormProps = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
  layout: 'horizontal'
};

export const formTailLayout = {
  wrapperCol: { offset: 4, span: 20 },
};

export const filterFormLayout: FormProps = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
  layout: 'inline'
};
