import { Dispatch } from '@redux/store';
import { createModel } from '@rematch/core';
import { AuthService, LanguageService } from '@services';
import { AppModel, RootModel, UserType } from '@types';

const initialState: AppModel = {
  // LAYOUT STATE
  layoutState: {
    isMenuTop: false,
    menuMobileOpened: false,
    menuCollapsed: false,
    menuShadow: false,
    lightTheme: localStorage.getItem('clik.theme') === 'light',
    squaredBorders: false,
    borderLess: true,
    fixedWidth: false,
    settingsOpened: false,
  },

  // USER
  user: undefined,
  languages: undefined,
};

export const app = createModel<RootModel>()({
  state: initialState,
  reducers: {
    enableLightTheme(state, payload) {
      state.layoutState.lightTheme = payload;
    },
    collapseMenu(state, payload) {
      state.layoutState.menuCollapsed = payload;
    },
    updateUserDetails(state, payload) {
      state.user = payload;
    },
    updateLanguages(state, payload) {
      state.languages = payload;
    },
  },
  effects: (dispatch: Dispatch) => ({
    async getUserDetailAsync() {
      const { data } = await AuthService.getUserDetails();
      const user: UserType = { ...data };
      user.permissions = data.roles.flatMap((r: any) => r.permissions).map((p: any) => p.name);
      this.updateUserDetails(user);
      this.getLanguageAsync();
      return user;
    },
    getLanguageAsync() {
      return LanguageService.getLanguages().then((res: any) => {
        const { data } = res;
        this.updateLanguages(data);
      });
    },
  }),
});
