import Loading from '@commons/components/Loading';
import useAuth from '@hooks/useAuth';
import { RootState } from '@redux/store';
import AppRouter from '@routers/routes';
import { Layout } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import CopyRight from '../CopyRight';
import MenuLeft from '../MenuLeft';
import TopMenu from '../TopMenu/TopMenu';
import cx from 'classnames';

import './styles.scss';

const Admin: React.FC = () => {
  const { user } = useAuth();
  const menuCollapsed = useSelector<RootState, boolean>(
    (state) => state.app?.layoutState.menuCollapsed
  );
  return user ? (
    <Layout className="cota-admin-layout">
      <MenuLeft />
      <div
        id="content-wrapper"
        className={cx('d-flex flex-column cota-admin-layout__content-wrapper', {
          'cota-admin-layout__content-wrapper--collapsed': menuCollapsed,
        })}
      >
        <TopMenu />
        <div id="content">
          <Layout className="cota-admin-layout__main-layout position-relative h-100">
            <Layout.Content className="main-layout__content">
              <AppRouter />
            </Layout.Content>
            <CopyRight />
          </Layout>
        </div>
      </div>
    </Layout>
  ) : (
    <Loading loading />
  );
};

export default React.memo(Admin);
