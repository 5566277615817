import { Dispatch, RootState } from '@redux/store';
import * as AuthService from '@services/auth.service';
import { axios } from '@services/baseAxios';
import { UserType } from '@types';
import { toParams } from '@utils/oauthUtils';
import { getRedirectUrl, getRefreshToken } from '@utils/storageUtils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const responseTypeLocationKeys = {
  code: 'search',
  token: 'hash',
};

const responseTypeDataKeys = {
  code: 'code',
  token: 'access_token',
};

const useAuth = (): { user?: UserType; authenticated: boolean } => {
  const history = useHistory();
  const user = useSelector<RootState, UserType | undefined>((state) => state.app.user);
  const dispatch = useDispatch<Dispatch>();

  const fetchUser = async () => {
    try {
      const userResult = await dispatch.app.getUserDetailAsync();
      if (!userResult) {
        AuthService.authorize();
      } else {
        const redirectUrl = getRedirectUrl();
        if (redirectUrl) {
          const url = new URL(redirectUrl);
          history.push(`${url.pathname}${url.search}`);
        }
      }
    } catch (err) {
      console.error('useAuth:', err);
      const refresh = getRefreshToken();
      if (!refresh) {
        AuthService.authorize();
      } else {
        AuthService.refreshToken({ onSuccess: fetchUser });
      }
    }
  };

  useEffect(() => {
    if (!user) {
      if (window.location.href.indexOf(AuthService.redirectUri!) !== -1) {
        const locationKey = responseTypeLocationKeys[AuthService.responseType];
        const search = window.location[locationKey];
        const params = toParams(search);
        const responseKey = responseTypeDataKeys[AuthService.responseType];
        AuthService.getToken({ code: params[responseKey], onSuccess: fetchUser });
        return;
      }

      if (axios.setToken) {
        axios.setToken();
      }

      fetchUser();
    }
  }, [user]);

  return { user, authenticated: !!user };
};

export default useAuth;
