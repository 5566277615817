import { SLUGS } from '@commons/constants';
import {
  PERMISSION_VIEW_BLOGS,
  PERMISSION_VIEW_BUS_TOURS,
  PERMISSION_VIEW_TRAVELINGS,
} from '@commons/constants/permissions';

export interface MenuDataItemType {
  title: string;
  key: string;
  url?: string;
  icon?: React.ReactNode;
  children?: MenuDataItemType[];
  regex?: RegExp;
  hidden?: boolean;
  permissions?: string[];
  parent?: string;
}

const menuData: MenuDataItemType[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    url: SLUGS.DASHBOARD,
    icon: <i className="fas fa-tachometer-alt"></i>,
  },
  {
    key: 'account',
    title: 'Account',
    regex: /^\/account/,
    hidden: true,
  },
  {
    title: 'Manage',
    key: 'manage-group',
    icon: <i className="fas fa-pencil-ruler" />,
    children: [
      {
        key: 'travelings',
        title: 'Traveling trips',
        url: SLUGS.TRAVELING_TRIP_MANAGEMENT,
        icon: <i className="fa-solid fa-route"></i>,
        parent: 'manage-group',
        permissions: [PERMISSION_VIEW_TRAVELINGS],
      },
      {
        key: 'blogs',
        title: 'Blogs',
        url: SLUGS.BLOGS_MANAGEMENT,
        icon: <i className="fa-solid fa-blog"></i>,
        parent: 'manage-group',
        permissions: [PERMISSION_VIEW_BLOGS],
      },
      {
        key: 'bus-tours',
        title: 'Bus Tours',
        url: SLUGS.BUS_TOURS_MANAGEMENT,
        icon: <i className="fa-solid fa-bus"></i>,
        parent: 'manage-group',
        permissions: [PERMISSION_VIEW_BUS_TOURS],
      },
    ],
  },
];

export default menuData;
