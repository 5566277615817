import { createModel } from '@rematch/core';
import { PageRequest, PaginationType, RootModel, TripCriteria, TripDtoType } from '@types';
import { TripService } from '@services';
import baseEntity from './base.entity';
import { Dispatch } from '@redux/store';

interface StateType {
  ids: { [id: number]: TripDtoType };
}

export const tripEntities = createModel<RootModel>()({
  state: { ids: {} } as StateType,
  reducers: {
    ...baseEntity,
  },
  effects: (dispatch: Dispatch) => ({
    async load(
      payload: PageRequest & TripCriteria
    ): Promise<{ content: TripDtoType[] } & PaginationType> {
      const res = await TripService.filter(payload);
      const { content } = res.data;
      if (content && content.length) {
        this.saveSimplified(content);
      }
      return res.data;
    },
    // async findById(payload, state): Promise<BlogType> {
    //   const item = state.blogEntities.ids[payload];
    //   if (item && !item.simplified) {
    //     return item;
    //   }
    //   const res = await TripService.getById(payload);
    //   this.saves([res.data]);
    //   return res.data;
    // },
    // async delete(payload) {
    //   await TripService.deleteById(payload);
    //   this.remove({ id: payload });
    // },
    // async save(payload) {
    //   let item;
    //   if (!payload.id) {
    //     ({ data: item } = await TripService.create(payload));
    //   } else {
    //     ({ data: item } = await TripServiceTripService.update(payload.id, payload));
    //   }
    //   this.update(item);
    //   return item;
    // },
    // async publish(payload) {
    //   const { data } = await TripService.publish(payload);
    //   this.update(data);
    //   return data;
    // },
  }),
});
