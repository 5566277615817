export const CONFIG_COLUMN_INDEX: any = {
  title: '',
  key: 'index',
  fixed: 'left',
  width: 50,
};

export const CONFIG_COLUMN_NAME: any = {
  title: 'Name',
  key: 'name',
  fixed: 'left',
  width: 200,
};

export const CONFIG_COLUMN_ACTIONS: any = {
  title: '',
  key: 'action',
  fixed: 'right',
  width: 250,
};