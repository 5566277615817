import { Layout } from 'antd';
import React, { FC } from 'react';

import './styles.scss';

const CopyRight: FC = () => {
  return (
    <Layout.Footer className="cota-copyright">
      <div className="copyright__text">
        {`Copyright ${new Date().getFullYear()} by `}
        <a rel="noreferrer" href="https://clik.vn" target="_blank">
          Clik
        </a>
        . All Rights Reserved.
      </div>
    </Layout.Footer>
  );
};

export default CopyRight;
