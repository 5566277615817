export const API_VERSION = '/v1/ext';
export const API_LOCATION_CMS = `${API_VERSION}/location`;

export const API_TRAVELING = '/traveling-trips';
export const API_BLOGS = '/blogs';
export const API_LANGUAGE = `/languages`;
export const API_TRIPS = `/trips`;
export const API_MEDIA = `/media`;
export const API_LOCATION = `/locations`;
export const API_USER_TRAVELING = `/user-travelings`;

export const AUTOCOMPLETE_LOCATIONS = `${API_LOCATION_CMS}`;
export const GET_LOCATION_DETAILS = `${API_LOCATION_CMS}/details`;
export const API_BUS_TOUR = `/bus-tours`;
