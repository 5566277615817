import {Dispatch, RootState} from '@redux/store';
import { Switch } from 'antd';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ThemeSwitch: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const lightTheme = useSelector<RootState, boolean>(
    (state) => state.app?.layoutState.lightTheme
  );

  const handleChangeTheme = (value: boolean) => {
    localStorage.setItem('cota.theme', value ? 'light' : 'dark');
    dispatch.app.enableLightTheme(value);
  };

  return (
    <Switch
      checked={!lightTheme}
      onChange={() => handleChangeTheme(!lightTheme)}
      checkedChildren={<i className="fas fa-moon fa-sm fa-fw mr-2"></i>}
      unCheckedChildren={<i className="fas fa-sun fa-sm fa-fw mr-2"></i>}
    />
  );
};

export default ThemeSwitch;
