import Admin from '@components/Admin';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { store } from '@redux/store';
import '@resources/icon-font-config.scss';
import '@resources/icon.css';
import '@resources/sb-admin-2.css';
import history from '@routers/history';
import { ConnectedRouter } from 'connected-react-router';
import React, { FC } from 'react';
import { Provider } from 'react-redux';
import './App.scss';

const App: FC = () => {
  return (
    <div className="App" id="wrapper">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Admin />
        </ConnectedRouter>
      </Provider>
    </div>
  );
};

export default App;
