import { DEFAULT_PAGE_SIZE } from '@commons/constants';
import { TablePaginationConfig } from 'antd';

export const getPaginationConfig = (props: {
  pagination: { number: number; totalElements: number; size: number };
  showSizeChanger?: boolean;
  onChange: (page: number) => void;
  onShowSizeChange?: (current: number, size: number) => void;
  hideOnSinglePage?: boolean;
}): TablePaginationConfig => {
  const {
    onChange,
    pagination,
    onShowSizeChange,
    showSizeChanger = true,
    hideOnSinglePage = false,
  } = props;
  const position: any[] = ['bottomRight'];
  if (pagination.totalElements > DEFAULT_PAGE_SIZE) {
    position.push('topRight');
  }
  return {
    position,
    showSizeChanger,
    hideOnSinglePage,
    onChange: onChange,
    showTotal: () => `Total ${pagination.totalElements} items`,
    onShowSizeChange: onShowSizeChange,
    defaultPageSize: DEFAULT_PAGE_SIZE,
    defaultCurrent: 1,
    pageSize: pagination.size,
    current: pagination.number + 1,
    total: pagination.totalElements,
  };
};
