export const isAudio = (fileName?: string): boolean => {
  return !!fileName && fileName.match(/\.(mp3|wav|wma)$/) != null;
};

import { CommonFileType, ExtendedFile, FileModel } from '@types';

export const instanceOfFileModel = (object: CommonFileType): object is FileModel => {
  return (
    !!Object.keys(object).find((k) => k === 'id') && !!Object.keys(object).find((k) => k === 'url')
  );
};

export const convertToFileField = (file: CommonFileType | null): ExtendedFile | null => {
  let mappedPreviewVideo;
  if (file) {
    if (instanceOfFileModel(file)) {
      mappedPreviewVideo = {
        ...file.file,
      };
    } else {
      mappedPreviewVideo = { ...file };
    }
  }
  return mappedPreviewVideo;
};
