import useRolePermissions from '@hooks/useRolePermissions';
import { Dispatch, RootState } from '@redux/store';
import { Layout, Menu } from 'antd';
import { FC, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { default as menuData } from './menuData';

import './style.scss';

const MenuLeft: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const pathname = useSelector<RootState>((state) => (state.router as any).location.pathname);

  const menuCollapsed = useSelector<RootState, boolean>(
    (state) => state.app?.layoutState.menuCollapsed
  );
  const lightTheme = useSelector<RootState, boolean>((state) => state.app?.layoutState.lightTheme);

  const [openKeys, setOpenKeys] = useState<Array<string>>(['']);
  const [selectedKeys, setSelectedKeys] = useState<string>('');

  const { hasAnyPermission } = useRolePermissions();

  const getPath = (data, id, parents: any[] = []) => {
    const items = data.reduce((result, entry) => {
      if (result.length > 0) {
        return result;
      }
      if (entry.url === id && selectedKeys === '') {
        return [entry].concat(parents);
      }
      if (entry.key === id && selectedKeys !== '') {
        return [entry].concat(parents);
      }
      if (entry.children) {
        const nested = getPath(entry.children, id, [entry].concat(parents));
        return nested || result;
      }
      return result;
    }, []);
    return items.length > 0 ? items : false;
  };

  const getActiveMenuItem = (items) => {
    const paths = getPath(items, selectedKeys || pathname || location.pathname);

    if (!paths) {
      return;
    }

    const activeMenuItem = paths.pop();
    let [...path] = paths;

    if (menuCollapsed) {
      path = [''];
    }

    setSelectedKeys(activeMenuItem ? activeMenuItem.key : '');
    setOpenKeys(activeMenuItem ? path.map((entry) => entry.key) : []);
  };

  useEffect(() => {
    getActiveMenuItem(menuData);
  }, [pathname]);

  const generateMenuItem = (item) => {
    const { key, title, url, icon, hidden } = item;

    if (hidden) {
      return null;
    }

    if (url) {
      return (
        <Menu.Item key={key} icon={icon}>
          <Link to={url} onClick={() => setSelectedKeys(key)}>
            <span>{title}</span>
          </Link>
        </Menu.Item>
      );
    }

    return (
      <Menu.Item key={key} icon={icon}>
        <Link to={url} onClick={() => setSelectedKeys(key)}>
          <span>{title}</span>
        </Link>
      </Menu.Item>
    );
  };

  const generateMenuPartitions = (items) => {
    return items
      .filter((menuItem) => {
        const { permissions = [] } = menuItem;
        return !permissions.length || hasAnyPermission(...permissions);
      })
      .map((menuItem) => {
        const { children, title, key, icon } = menuItem;

        if (children) {
          return (
            <Menu.SubMenu
              key={key}
              icon={<span className="anticon">{icon}</span>}
              title={title}
              onTitleClick={() => setSelectedKeys(key)}
            >
              {generateMenuPartitions(children)}
            </Menu.SubMenu>
          );
        }
        return generateMenuItem(menuItem);
      });
  };

  const menuItems = generateMenuPartitions(menuData);
  const theme = lightTheme ? 'light' : 'dark';

  return (
    <Layout.Sider
      className="cota-menu-left__wrapper"
      onCollapse={() => dispatch.app.collapseMenu(!menuCollapsed)}
      collapsible
      collapsed={menuCollapsed}
      theme={theme}
    >
      <div className={`menu-left__logo-wrapper menu-left__logo-wrapper--${theme}`}>
        <a className="logo__image" href="/">
          <div className="sidebar-brand-icon">
            {!lightTheme ? (
              <img alt="Clik" src={`${process.env.PUBLIC_URL}/images/logo/logo-reverse.svg`} />
            ) : (
              <img alt="Clik" src={`${process.env.PUBLIC_URL}/images/logo/logo.svg`} />
            )}
          </div>
          {!menuCollapsed && (
            <div className="logo__text">
              <sup>Otrip Admin</sup>
            </div>
          )}
        </a>
      </div>
      <PerfectScrollbar style={{ height: 'calc(100vh - 112px)' }}>
        <Menu
          theme={theme}
          inlineCollapsed={menuCollapsed}
          mode="inline"
          defaultOpenKeys={[selectedKeys]}
          selectedKeys={openKeys}
          className="h-100"
        >
          {menuItems}
        </Menu>
      </PerfectScrollbar>
    </Layout.Sider>
  );
};

export { menuData };

export default MenuLeft;
