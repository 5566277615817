import {
  PageRequest,
  PaginationResponseType,
  ResponseType,
  TravelingDtoType,
  UserTravelingCriteria,
  UserTravelingDtoType,
  UserType,
} from '@types';
import { axios, get, post } from './baseAxios';
import { API_USER_TRAVELING } from './urls';

const BASE_URL = API_USER_TRAVELING;

export const filterUserTravelings = (
  request: PageRequest & UserTravelingCriteria = {}
): Promise<PaginationResponseType<UserTravelingDtoType[]>> => {
  const { sort, ...rest } = request;
  const params: any = {
    ...rest,
  };

  if (sort?.length) {
    const firstSort = sort![0];
    params.sort = `${firstSort.property!},${firstSort.direction!.toLowerCase()}`;
  }
  return get(BASE_URL, { params });
};

export const createUserTraveling = (data: {
  traveling: Pick<TravelingDtoType, 'id'>;
  user: Pick<UserType, 'id'>;
}): Promise<ResponseType<UserTravelingDtoType>> => {
  return post(BASE_URL, data);
};

export const deleteUserTraveling = (id: number): Promise<ResponseType<boolean>> => {
  return axios.delete(`${BASE_URL}/${id}`);
};

export const getShareableUsers = (
  id: number,
  req: any
): Promise<PaginationResponseType<UserType[]>> => {
  return get(`${BASE_URL}/travelings/${id}/shareable-users`, { params: req });
};

export const getSharingByUser = (id: number): Promise<ResponseType<UserTravelingDtoType[]>> => {
  return get(`${BASE_URL}/users/${id}/sharing`);
};
