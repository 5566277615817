import { RootState } from '@redux/store';
import { Menu } from 'antd';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import './styles.scss';
import ThemeSwitch from './Theme';
import TopMenuAccount from './TopMenuAccount';

const TopMenu: FC = () => {
  const themeLight = useSelector<RootState, boolean>((state) => state.app.layoutState.lightTheme);

  return (
    <Menu theme={themeLight ? 'light' : 'dark'}>
      <div className="cota-top-menu__wrapper">
        <div className="top-menu__block-right">
          <div className="top-menu__theme-switch">
            <ThemeSwitch />
          </div>
          <TopMenuAccount />
        </div>
      </div>
    </Menu>
  );
};

export default TopMenu;
