import Loading from '@commons/components/Loading';
import { SLUGS } from '@commons/constants';
import { ComponentType } from 'react';
import Loadable from 'react-loadable';

interface LoadableRoutesType {
  [key: string]: {
    component: React.ComponentType & Loadable.LoadableComponent;
    exact?: boolean;
    permissions?: string[];
  };
}

const loadable = (loader: () => Promise<ComponentType<any> | { default: ComponentType<any> }>) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loading />,
  });

const loadableRoutes: LoadableRoutesType = {
  // Dashboard
  '/': {
    component: loadable(() => import('../pages/Dashboard')),
  },
  [SLUGS.DASHBOARD]: {
    component: loadable(() => import('../pages/Dashboard')),
  },

  // Travelings
  [SLUGS.TRAVELING_TRIP_MANAGEMENT]: {
    component: loadable(() => import('../pages/TravelingTripManagement')),
  },
  [SLUGS.TRAVELING_TRIP_DETAIL]: {
    component: loadable(() => import('../pages/TravelingTripManagement/TravelingDetail')),
  },
  // Blogs
  [SLUGS.BLOGS_MANAGEMENT]: {
    component: loadable(() => import('../pages/BlogManagement')),
  },
  [SLUGS.BLOGS_DETAIL]: {
    component: loadable(() => import('../pages/BlogManagement/BlogDetail')),
  },
  // Bus tours
  [SLUGS.BUS_TOURS_MANAGEMENT]: {
    component: loadable(() => import('../pages/BusTourManagement')),
  },
  [SLUGS.BUS_TOUR_DETAIL]: {
    component: loadable(() => import('../pages/BusTourManagement/BusTourDetail')),
  },
};

export default loadableRoutes;
