import { UploadFile } from 'antd/lib/upload/interface';
import { FileType } from './common.type';

export interface FileModel {
  id?: number;
  name?: string;
  code?: string;
  altText?: string;
  caption?: string;
  type?: FileType;
  file: StorageFile;
  tags?: string[];

  // merge FileFieldValue
  preview?: string;
  uniqueId?: string;
}

export interface SourceFolder {
  id: number;
  name: string;
  originalName: string;
  url: string;
}

interface StorageFile {
  id?: number;
  name?: string;
  url: string;
  originalName?: string;
  mimeType?: string;
  width?: number;
  height?: number;
  thumbnail?: string;
}

export interface ExtendedFile extends File {
  preview?: string;
  uniqueId?: string;
  thumbnail?: string;
}

export const instanceOfFileModel = (object: CommonFileType): object is FileModel => {
  return (
    !!Object.keys(object).find((k) => k === 'id') && !!Object.keys(object).find((k) => k === 'url')
  );
};

export type CommonFileType = FileModel | ExtendedFile;

export interface UploadedFile extends UploadFile {
  id: number;
}
