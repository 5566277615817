/* eslint-disable camelcase */
import { UserType } from '@types';
import { toQuery } from '@utils/oauthUtils';
import { getRefreshToken, removeAccessToken, setRedirectUrl } from '@utils/storageUtils';
import { axios, get } from './baseAxios';

interface AuthorizeType {
  // eslint-disable-next-line camelcase
  client_id?: string;
  scope: string;
  // eslint-disable-next-line camelcase
  redirect_uri?: string;
  // eslint-disable-next-line camelcase
  response_type: string;
  state?: string;
}

const contextPath = process.env.REACT_APP_API_VERSION;
const serverUrl = process.env.REACT_APP_OAUTH_DOMAIN;
const authorizationUrl = `${serverUrl}/oauth/authorize`;
const getTokenUrl = `${serverUrl}/oauth/token`;
const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
const redirectUri = process.env.REACT_APP_REDIRECT_URI;
const state = '12345';
const scope = 'read';
const responseType = 'code';

const getUserDetails = (): Promise<{ data: UserType }> => {
  const origin = process.env.REACT_APP_API_HOST;
  return get(`${origin}${contextPath}/user/profile`);
};

const getToken = ({ code, onSuccess }: { code: string; onSuccess: () => void }): void => {
  const payload = {
    grant_type: 'authorization_code',
    client_id: clientId,
    client_secret: clientSecret,
    code: code,
    redirect_uri: redirectUri,
  };
  const search = toQuery(payload);

  fetch(`${getTokenUrl}?${search}`)
    .then((res) => res.json())
    .then((data) => {
      if (axios.setToken) {
        axios.setToken(data);
      }
      if (onSuccess) {
        onSuccess();
      }
    });
};

const refreshToken = ({ onSuccess }: { onSuccess: () => void }) => {
  const formData = new FormData();
  formData.append('grant_type', 'refresh_token');
  formData.append('client_id', clientId!);
  formData.append('client_secret', clientSecret!);
  formData.append('refresh_token', getRefreshToken()!);
  fetch(`${getTokenUrl}`, {
    method: 'POST',
    body: formData,
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new Error('refresh token error');
    })
    .then((data) => {
      if (axios.setToken) {
        axios.setToken(data);
      }
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.error(err);
      removeAccessToken();
      authorize();
    });
};

const authorize = (): void => {
  const payload: AuthorizeType = {
    client_id: clientId,
    scope,
    redirect_uri: redirectUri,
    response_type: responseType,
  };
  if (state) {
    payload.state = state;
  }

  const search = toQuery(payload);
  setRedirectUrl(window.location.href);
  window.location.href = `${authorizationUrl}?${search}`;
};

export { getUserDetails, authorize, getToken, redirectUri, responseType, refreshToken };
