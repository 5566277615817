export const CONFIG_TRAVELING_STEPS = {
  ADD_VIEWS: {
    key: 'ADD_VIEWS',
    label: 'Views',
    orderIndex: 0,
    queryKey: 've',
  },
  CONFIG_ROUTE: {
    key: 'CONFIG_ROUTE',
    label: 'Route',
    orderIndex: 1,
    queryKey: 'ro',
  },
  ADD_REF_POINTS: {
    key: 'ADD_REF_POINTS',
    label: 'Ref Points',
    orderIndex: 2,
    queryKey: 'rf',
  },
  // REVIEW: {
  //   key: 'REVIEW',
  //   label: 'Review',
  //   orderIndex: 3,
  //   queryKey: 'rv',
  // },
};

export const CONFIG_TRAVELING_QUERY_KEY = 'stp';
