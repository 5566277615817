import { EntityType } from '@types';
import { blogEntities } from './blog.entity';
import { locationEntities } from './location.entity';
import { mediaEntities } from './media.entity';
import { travelingEntities } from './traveling.entity';
import { tripEntities } from './trip.entity';
import { userTravelingEntities } from './userTraveling.entity';
import { busTourEntities } from './bustour.entity';

export const entities: EntityType = {
  locationEntities,
  travelingEntities,
  blogEntities,
  tripEntities,
  mediaEntities,
  userTravelingEntities,
  busTourEntities
};
