import { RootState } from '@redux/store';
import { UserType } from '@types';
import { useSelector } from 'react-redux';

const useRolePermissions: any = () => {
  const user = useSelector<RootState, UserType | undefined>((state) => state.app.user);

  const hasPermission = (name: string) => {
    if (!user || !user.permissions || !user.permissions.length) {
      return false;
    }

    return user.permissions.includes(name);
  };

  const hasRole = (name: string) => {
    if (!user || !user.roles || !user.roles.length) {
      return false;
    }

    const lName = name.toLowerCase();
    return user.roles.some((role) => role.name.toLowerCase() === lName);
  };

  const hasAnyPermission = (...names: string[]) => {
    if (!user || !user.permissions || !user.permissions.length) {
      return false;
    }

    return names.some((name) => {
      return hasPermission(name);
    });
  };

  return {
    hasPermission,
    hasAnyPermission,
    hasRole,
  };
};

export default useRolePermissions;
