import { RouteParamsType } from '@types';

export const CREATE_PAGE = 'create';

export const ROUTE_PARAMS_VARIABLE: RouteParamsType = {
  travelingId: 'travelingId',
  blogId: 'blogId',
  busTourId: 'busTourId',
};

export const ROUTE_PARAMS: RouteParamsType = {
  travelingId: `:${ROUTE_PARAMS_VARIABLE.travelingId}`,
  blogId: `:${ROUTE_PARAMS_VARIABLE.blogId}`,
  busTourId: `:${ROUTE_PARAMS_VARIABLE.busTourId}`,
};

// traveling trips
export const TRAVELING_TRIP_MANAGEMENT = `/traveling-trips`;
export const TRAVELING_TRIP_DETAIL = `${TRAVELING_TRIP_MANAGEMENT}/${ROUTE_PARAMS.travelingId}`;

// traveling blogs
export const BLOGS_MANAGEMENT = `/blogs`;
export const BLOGS_DETAIL = `${BLOGS_MANAGEMENT}/${ROUTE_PARAMS.blogId}`;

// traveling bus tours
export const BUS_TOURS_MANAGEMENT = `/bus-tours`;
export const BUS_TOUR_DETAIL = `${BUS_TOURS_MANAGEMENT}/${ROUTE_PARAMS.busTourId}`;

export const ROUTE_PAGES_URL: RouteParamsType = {
  travelingId: TRAVELING_TRIP_DETAIL,
  blogId: BLOGS_DETAIL,
  busTourId: BUS_TOUR_DETAIL,
};

export const SLUGS = {
  DASHBOARD: '/dashboard',
  TRAVELING_TRIP_MANAGEMENT,
  TRAVELING_TRIP_DETAIL,
  BLOGS_MANAGEMENT,
  BLOGS_DETAIL,
  BUS_TOURS_MANAGEMENT,
  BUS_TOUR_DETAIL,
};
