import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_KEY } from '@commons/constants';
import { Dispatch } from '@redux/store';
import { createModel } from '@rematch/core';
import { ConfigurationModel, RootModel } from '@types';
import { message } from 'antd';

const initialState: ConfigurationModel = {
  loading: false,
  size: DEFAULT_PAGE_SIZE,
};

export const configuration = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updateSize(state, payload) {
      state.size = payload;
    },
    updateLoading(state, payload) {
      state.loading = payload;
    },
  },
  effects: (dispatch: Dispatch) => ({
    getConfiguration() {
      this.updateLoading(true);
      try {
        const size = Number(
          window.localStorage.getItem(DEFAULT_PAGE_SIZE_KEY) || DEFAULT_PAGE_SIZE
        );
        this.updateSize(size);
        return { size };
      } catch (e) {
        console.error(e);
        message.error('Failed to get configuration');
      } finally {
        this.updateLoading(false);
      }
    },
    updateConfiguration(payload) {
      this.updateLoading(true);
      const { size } = payload;
      try {
        window.localStorage.setItem(DEFAULT_PAGE_SIZE_KEY, size);
        this.updateSize(Number(size));
        message.success('Save configuration successfully');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } catch (err) {
        console.error(err);
        message.error('Fail to update configuration');
      } finally {
        this.updateLoading(false);
      }
    },
  }),
});
