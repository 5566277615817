import {ResponseType, PaginationResponseType, PageRequest} from '@types';
import { BlogType } from '@types';
import { BlogCriteria } from '@types';
import { axios, get, post, put } from './baseAxios';
import { API_BLOGS } from './urls';

const url = API_BLOGS;

const create = (request: BlogType): Promise<ResponseType<BlogType>> =>
    post(`${url}`, request);

const update = (id: number, request: BlogType): Promise<ResponseType<BlogType>> =>
    put(`${url}/${id}`, request);

const deleteById = (id: number): Promise<ResponseType<boolean>> =>
    axios.delete(`${url}/${id}`);

const getById = (id: number): Promise<ResponseType<BlogType>> => get(`${url}/${id}`);

const filter = (
    request: PageRequest & BlogCriteria
): Promise<PaginationResponseType<BlogType[]>> => get(`${url}`, { params: { ...request } });

const publish = (request: BlogType): Promise<ResponseType<BlogType>> =>
    put(`${url}/${request.id}/published`, request);

export {
    create, update, deleteById, getById, filter, publish
}