import {
  BusTourCriteria,
  BusTourType,
  PageRequest,
  PaginationResponseType,
  ResponseType,
} from '@types';
import { axios, get, post, put } from './baseAxios';
import { API_BUS_TOUR } from './urls';

const url = API_BUS_TOUR;

const create = (request: BusTourType): Promise<ResponseType<BusTourType>> =>
  post(`${url}`, request);

const update = (id: number, request: BusTourType): Promise<ResponseType<BusTourType>> =>
  put(`${url}/${id}`, request);

const deleteById = (id: number): Promise<ResponseType<boolean>> => axios.delete(`${url}/${id}`);

const getById = (id: number): Promise<ResponseType<BusTourType>> => get(`${url}/${id}`);

const filter = (
  request: PageRequest & BusTourCriteria
): Promise<PaginationResponseType<BusTourType[]>> => get(`${url}`, { params: { ...request } });

const publish = (request: BusTourType): Promise<ResponseType<BusTourType>> =>
  put(`${url}/${request.id}/published`, request);

export { create, update, deleteById, getById, filter, publish };
