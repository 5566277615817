import { Link } from 'react-router-dom';

export const itemRender = (route: any, params: any, routes: Array<any>, paths: Array<string>) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  );
};
