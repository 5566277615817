import { redirectUri } from '@services/auth.service';

const TOKEN = 'cota_token';
const REDIRECT_URL = 'cota_redirect_url';
const REFRESH_TOKEN = 'cota_refresh_token';
const EXPIRED_TIME = 'cota_expired_time';

export const getAccessToken = (): string | null => {
  const tokenData = localStorage.getItem(TOKEN);
  const expiredTimeToken = getExpiredTime();
  if (!expiredTimeToken || expiredTimeToken < Date.now()) {
    return null;
  }
  return tokenData;
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const getExpiredTime = (): number | null => {
  const exp = localStorage.getItem(EXPIRED_TIME);
  return exp ? Date.parse(exp) : null;
};

export const setAccessToken = (value: string): void => {
  localStorage.setItem(TOKEN, value);
};

export const setRefreshToken = (value: string): void => {
  localStorage.setItem(REFRESH_TOKEN, value);
};

export const setExpiredTime = (value = 0): void => {
  const date = new Date();
  date.setSeconds(date.getSeconds() + value);
  localStorage.setItem(EXPIRED_TIME, date.toLocaleString());
};

export const removeAccessToken = (): void => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(EXPIRED_TIME);
};

export const setRedirectUrl = (url: string): void => {
  if (url && redirectUri && !window.location.href.startsWith(redirectUri)) {
    localStorage.setItem(REDIRECT_URL, url);
  }
};

export const getRedirectUrl = (): string | null => {
  const url = localStorage.getItem(REDIRECT_URL);
  localStorage.removeItem(REDIRECT_URL);
  return url;
};