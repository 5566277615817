import { createModel } from '@rematch/core';
import { BusTourType, PaginationType, RootModel } from '@types';
import { BusTourService } from '@services';
import { Dispatch } from '@redux/store';
import baseEntity from './base.entity';

interface StateType {
  ids: { [id: number]: BusTourType };
}

export const busTourEntities = createModel<RootModel>()({
  state: { ids: {} } as StateType,
  reducers: {
    ...baseEntity,
  },
  effects: (dispatch: Dispatch) => ({
    async load(payload): Promise<{ content: BusTourType[] } & PaginationType> {
      const res = await BusTourService.filter(payload);
      const { content } = res.data;
      if (content && content.length) {
        this.saveSimplified(content);
      }
      return res.data;
    },
    async findById(payload, state): Promise<BusTourType> {
      const item = state.busTourEntities.ids[payload];
      if (item && !item.simplified) {
        return item;
      }
      const res = await BusTourService.getById(payload);
      this.saves([res.data]);
      return res.data;
    },
    async delete(payload) {
      await BusTourService.deleteById(payload);
      this.remove({ id: payload });
    },
    async save(payload) {
      let item;
      if (!payload.id) {
        ({ data: item } = await BusTourService.create(payload));
      } else {
        ({ data: item } = await BusTourService.update(payload.id, payload));
      }
      this.update(item);
      return item;
    },
    async publish(payload) {
      const { data } = await BusTourService.publish(payload);
      this.update(data);
      return data;
    },
  }),
});
