import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import immerPlugin from '@rematch/immer';
import { RootModel } from '@types';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { thunk } from 'redux-thunk';

import history from './history';
import { models } from './models';

const router = routerMiddleware(history);
const middlewares = [router, thunk];

export const store = init({
  models,
  redux: {
    reducers: {
      router: connectRouter(history),
    },
    devtoolOptions: {
      realtime: true,
      name: 'Clik OTRIP Admin',
    },
    middlewares,
  },
  plugins: [immerPlugin<RootModel>()],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
