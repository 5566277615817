import { CommonFileType, ExtendedFile, FileModel } from '@types';

export const getImageSrc = (image: CommonFileType): string => {
  const imgFile = image as FileModel;
  const imgExtFile = image as ExtendedFile;
  if (imgFile.file.url) {
    return imgFile.file.url;
  }
  if (imgExtFile.preview) {
    return imgExtFile.preview;
  }

  return '';
};

export const isImage = (fileName?: string): boolean => {
  return !!fileName && fileName.match(/\.(jpeg|jpg|gif|png|svg)$/) != null;
};
