import { PaginationType } from '@types';

export const DEFAULT_PAGE_SIZES = {
  xs: 10,
  sm: 20,
  md: 50,
  lg: 100,
};

export const DEFAULT_PAGE_SIZE_KEY = 'otrip-config-page-size';
export const DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZES.sm;

export const DEFAULT_PAGINATION: PaginationType = {
  totalPages: 0,
  totalElements: 0,
  last: false,
  first: true,
  numberOfElements: 0,
  size: Number(window.localStorage.getItem(DEFAULT_PAGE_SIZE_KEY) || DEFAULT_PAGE_SIZE),
  number: 0,
  empty: true,
};
