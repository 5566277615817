import _ from 'lodash';
import { DEFAULT_LANG_CODE } from '@commons/constants';

export const convertDefaultValueToFormData = (data: any, langs) => {
  if (!data || !Object.keys(data).length) {
    return {};
  }
  const formData = {};
  langs.forEach((lang) => {
    formData[lang.code] = { ...data };
  });
  return formData;
};

export const convertEntityToFormData = (entity: any) => {
  if (!entity || !Object.keys(entity).length) {
    return {};
  }
  const formData = {
    [entity.language.code]: { ...entity },
  };

  entity.translations.forEach((translation) => {
    formData[translation.language.code] = { ...translation };
  });
  return formData;
};

export const convertFormDataToEntity = (
  formData: { [name: string]: any },
  baseOnMainFields?: string[]
) => {
  if (!formData) {
    return null;
  }
  let entity: any = null;
  Object.keys(formData).forEach((languageCode) => {
    const data = _.cloneDeep(formData[languageCode]);
    data.language = { code: languageCode };
    if (baseOnMainFields && baseOnMainFields.length && languageCode !== DEFAULT_LANG_CODE) {
      baseOnMainFields.forEach((field) => {
        data[field] = entity[field];
      });
    }
    if (languageCode === DEFAULT_LANG_CODE) {
      entity = data;
      entity.translations = [];
    } else {
      entity.translations.push(data);
    }
  });

  return entity;
};
