import { DownOutlined } from '@ant-design/icons';
import { RootState } from '@redux/store';
import { UserType } from '@types';
import { removeAccessToken } from '@utils/storageUtils';
import { Avatar, Dropdown, Menu } from 'antd';
import md5 from 'md5';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import './styles.scss';

const TopMenuAccount: FC = () => {
  const user = useSelector<RootState, UserType | undefined>((state) => state.app.user);
  const getAvatarSrc = ({ avatar, email }) => {
    if (avatar && avatar.length > 0) {
      return avatar;
    }

    return `//www.gravatar.com/avatar/${md5(email)}?d=identicon`;
  };

  const handleRedirectToAccount = () => {
    window.location.href = `${process.env.REACT_APP_ACCOUNT_URI}/my-profile`;
  };

  if (!user) {
    return null;
  }

  const menu = (
    <Menu className="menu-profile">
      <Menu.Item
        className="profile"
        icon={<Avatar size={48} src={getAvatarSrc({ avatar: user.avatar, email: user.email })} />}
        onClick={handleRedirectToAccount}
      >
        <p className="details">
          <span className="title">{user.name}</span>
          <span className="sub-title">{user.email}</span>
        </p>
      </Menu.Item>
      <Menu.Item
        icon={<i className="fas fa-user fa-sm fa-fw mr-2 text-gray-500"></i>}
        onClick={handleRedirectToAccount}
      >
        Manage my account
      </Menu.Item>
      <Menu.Item
        icon={<i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-500"></i>}
        onClick={() => {
          removeAccessToken();
          window.location.href = `${process.env.REACT_APP_OAUTH_DOMAIN}/exit?redirect_url=${window.location.href}`;
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="top-menu-account">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        className="user-info-dropdown"
        overlayClassName="top-menu-account"
      >
        <a role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <Avatar className="mr-2" src={getAvatarSrc({ avatar: user.avatar, email: user.email })} />
          <span className="mr-2">{user.name}</span>
          <DownOutlined />
        </a>
      </Dropdown>
    </div>
  );
};

export default TopMenuAccount;
