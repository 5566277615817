import { PageRequest, PaginationResponseType, TripCriteria, TripDtoType } from '@types';
import { get } from './baseAxios';
import { API_TRIPS } from './urls';

const url = API_TRIPS;

// const create = (request: TripDtoType): Promise<ResponseType<TripDtoType>> =>
//     post(`${url}`, request);
//
// const update = (id: number, request: TripDtoType): Promise<ResponseType<TripDtoType>> =>
//     put(`${url}/${id}`, request);
//
// const deleteById = (id: number): Promise<ResponseType<boolean>> =>
//     axios.delete(`${url}/${id}`);
//
// const getById = (id: number): Promise<ResponseType<TripDtoType>> => get(`${url}/${id}`);

const filter = (
  request: PageRequest & TripCriteria
): Promise<PaginationResponseType<TripDtoType[]>> => get(`${url}`, { params: { ...request } });

// const publish = (request: TripDtoType): Promise<ResponseType<TripDtoType>> =>
//     put(`${url}/${request.id}/published`, request);

export { filter };
