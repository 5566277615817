import { get, put } from '@services/baseAxios';
import { API_LOCATION } from '@services/urls';
import { LocationDtoType, ResponseType } from '@types';

export const updateLocation = async (
  data: LocationDtoType
): Promise<ResponseType<LocationDtoType>> => {
  return put(`${API_LOCATION}/${data.id}`, data);
};

export const getLocation = async (id: number): Promise<ResponseType<LocationDtoType>> => {
  return get(`${API_LOCATION}/${id}`);
};
