import axios from 'axios';
import { post } from './baseAxios';
import { CommonFileType, ExtendedFile, FileModel, instanceOfFileModel } from '@types';

const uploadMultiToFolder = async (files: ExtendedFile[]): Promise<any> => {
  const filenames: any[] = files.map((f) => f.name);
  const map = files.reduce((result, x) => {
    result[x.name] = x;
    return result;
  }, {});

  const { data: presignedUrls } = await post('/uploader/presigned-url', { files: filenames });
  const uploadedFiles = await Promise.all(
    Object.keys(presignedUrls).map(async (name) => {
      const { key, url, code } = presignedUrls[name];
      const file = map[name];
      const options = { headers: { 'Content-Type': file.type } };
      await axios.put(url, file, options);
      return { key, name, code };
    })
  );

  return post('/uploader/presigned-url/ack', {
    files: uploadedFiles,
  });
};
const uploadFiles = async (
  files: CommonFileType[] | CommonFileType,
  name: string
): Promise<{ name: string; filesList: FileModel[] }> => {
  const targetFiles: CommonFileType[] = Array.isArray(files) ? files : [files];
  let filesList: FileModel[] = [];
  const newFiles: ExtendedFile[] = [];
  targetFiles.forEach((f) => {
    if (instanceOfFileModel(f) && f.id) {
      filesList.push(f);
    } else {
      newFiles.push(f as ExtendedFile);
    }
  });

  if (!newFiles.length) {
    return { name: name, filesList };
  }
  console.log('Files will be uploaded', newFiles);

  try {
    const res = await uploadMultiToFolder(newFiles);
    const { data } = res;
    const uploadedFiles: FileModel[] = Object.values(data.photoIds);
    const savedPhotos: FileModel[] = [];
    targetFiles.forEach((f_1) => {
      if (
        instanceOfFileModel(f_1) &&
        f_1.id &&
        uploadedFiles.findIndex((p: any) => p.id === f_1.id) < 0
      ) {
        savedPhotos.push(f_1);
      }
    });
    filesList = uploadedFiles.concat(savedPhotos);
    return { name: name, filesList };
  } catch (err) {
    console.error(err);
    return { name: name, filesList };
  }
};

export { uploadMultiToFolder, uploadFiles };
