export interface SimplifiedModel {
  simplified: boolean;
}

export interface PaginationResponseType<T> {
  data: {
    content: T;
  } & PaginationType;
}

export interface ResponseType<T> {
  data: T;
}

export interface PaginationSortType {
  direction?: string;
  property?: string;
  ascending?: boolean;
  descending?: boolean;
}

export interface PaginationType {
  pageable?: {
    sort?: PaginationSortType[];
    offset?: number;
    pageNumber?: number;
    pageSize?: number;
    paged?: boolean;
    unpaged?: boolean;
  };
  sort?: PaginationSortType[];
  totalPages?: number;
  totalElements?: number;
  last?: boolean;
  first?: boolean;
  numberOfElements?: number;
  size?: number;
  number?: number;
  empty?: boolean;
}

export interface PageRequest {
  page?: number;
  size?: number;
  sort?: PaginationSortType[];
}

export interface LatLngType {
  lat: number;
  lng: number;
}

export enum FileType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  YOUTUBE = 'YOUTUBE',
  AUDIO = 'AUDIO',
}
