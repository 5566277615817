import { userTraveling } from '@redux/model/userTraveling.model';
import { ModelType } from '@types';
import { app } from './app.model';
import { blogs } from './blog.model';
import { configuration } from './configuration.model';
import { medias } from './media.model';
import { traveling } from './traveling.model';
import { busTours } from './bustour.model';

export const model: ModelType = {
  app,
  configuration,
  traveling,
  blogs,
  medias,
  userTraveling,
  busTours,
};
