import React, { FC } from 'react';
import { Route } from 'react-router';
import useRolePermissions from '../hooks/useRolePermissions';
import NotFoundPage from '../pages/NotFoundPage';
import loadableRoutes from './loadableRoutes';
import { ConnectedSwitch } from './reactRouterConnected';

const Routes: FC = () => {
  const { hasAnyPermission } = useRolePermissions();

  return (
    <ConnectedSwitch>
      {
        Object.keys(loadableRoutes)
          .filter((path) => {
            const { permissions = [] } = loadableRoutes[path];
            return !permissions.length || hasAnyPermission(...permissions);
          })
          .map((path) => {
            let { exact = true } = loadableRoutes[path];
            const { component: WrappedComponent, ...routeProps } = loadableRoutes[path];
            exact = !!exact; // set true as default
            const WrapperComponent: FC = (props: any) => (
              <WrappedComponent {...routeProps} {...props} />
            );
            return <Route key={path} path={path} exact={exact} component={WrapperComponent} />;
          })
      }
      <Route render={() => <NotFoundPage />} />
    </ConnectedSwitch>
  );
};

export { loadableRoutes };
export default Routes;
