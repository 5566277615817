import { BlogCriteria, FileModel, PageRequest, PaginationResponseType, ResponseType } from '@types';
import { axios, get, post, put } from './baseAxios';
import { API_MEDIA } from './urls';

const url = API_MEDIA;

const create = (request: FileModel): Promise<ResponseType<FileModel>> => post(`${url}`, request);

const update = (id: number, request: FileModel): Promise<ResponseType<FileModel>> =>
  put(`${url}/${id}`, request);

const deleteById = (id: number): Promise<ResponseType<boolean>> => axios.delete(`${url}/${id}`);

const getById = (id: number): Promise<ResponseType<FileModel>> => get(`${url}/${id}`);

const filter = (request: PageRequest & BlogCriteria): Promise<PaginationResponseType<FileModel[]>> =>
  get(`${url}`, { params: { ...request } });

export { create, update, deleteById, getById, filter };
