import { createModel } from '@rematch/core';
import { FileModel, PaginationType, RootModel } from '@types';
import { MediaService } from '@services';
import baseEntity from './base.entity';
import { Dispatch } from '@redux/store';

interface StateType {
  ids: { [id: number]: FileModel };
}

export const mediaEntities = createModel<RootModel>()({
  state: { ids: {} } as StateType,
  reducers: {
    ...baseEntity,
  },
  effects: (dispatch: Dispatch) => ({
    async load(payload): Promise<{ content: FileModel[] } & PaginationType> {
      const res = await MediaService.filter(payload);
      const { content } = res.data;
      if (content && content.length) {
        this.saveSimplified(content);
      }
      return res.data;
    },
    async findById(payload, state): Promise<FileModel> {
      const res = await MediaService.getById(payload);
      this.saves([res.data]);
      return res.data;
    },
    async delete(payload) {
      await MediaService.deleteById(payload);
      this.remove({ id: payload });
    },
    async save(payload) {
      let item;
      if (!payload.id) {
        ({ data: item } = await MediaService.create(payload));
      } else {
        ({ data: item } = await MediaService.update(payload.id, payload));
      }
      this.update(item);
      return item;
    },
  }),
});
