import { createModel } from '@rematch/core';
import { message } from 'antd';
import produce from 'immer';
import { MediaModelType, FileModel, PageRequest, PaginationType, RootModel } from '@types';
import { DEFAULT_PAGINATION, FILE_NAME_TYPE } from '@commons/constants';
import { Dispatch } from '@redux/store';
import { UploaderService } from '@services';

const initialState: MediaModelType = {
  ids: [],
  loading: false,
  editId: null,
  pagination: DEFAULT_PAGINATION,
  criteria: {
    keyword: '',
  },
};

export const medias = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setIds: produce((state, payload) => {
      state.ids = payload;
    }),
    setPagination: produce((state, payload) => {
      state.pagination = payload;
    }),
    setEditId: produce((state, payload) => {
      state.editId = payload;
    }),
    updateCriteria: produce((state, payload) => {
      state.criteria = payload;
    }),
    updateLoading: produce((state, payload) => {
      state.loading = payload;
    }),
  },
  effects: (dispatch: Dispatch) => ({
    async reloadList(payload, state): Promise<void> {
      const { criteria, pagination } = state.blogs;
      await this.filterAsync({ ...criteria, ...pagination });
    },

    async filterAsync(payload): Promise<void> {
      this.updateLoading(true);
      try {
        let readyPayload = payload;
        if (readyPayload && readyPayload.sort) {
          const clonedPayload = { ...readyPayload };
          const { sort } = clonedPayload;
          if (sort) {
            clonedPayload.sort = sort.map((i) => `${i.property},${i.direction}`).join(',');
          }
          readyPayload = clonedPayload;
        }
        const data: { content: FileModel[] } & PaginationType =
          await dispatch.mediaEntities.load(readyPayload);
        if (data && data.content) {
          this.setIds(data.content.map((scene) => scene.id));
          this.setPagination((({ content, pageable, ...otherProps }) => otherProps)(data));
        }
        this.updateCriteria(payload);
      } catch (e) {
        console.error(e);
        message.error('Failed to filter');
      } finally {
        this.updateLoading(false);
      }
    },

    async pagingAsync(payload: PageRequest, state): Promise<void> {
      const { criteria = {}, pagination = {} } = state.blogs;
      await this.filterAsync({ ...criteria, ...pagination, ...payload });
    },

    async getAsync(payload, state): Promise<void> {
      this.updateLoading(true);
      try {
        const item = await dispatch.mediaEntities.findById(payload);
        this.setEditId(item.id);
        this.updateLoading(false);
      } catch (e) {
        console.error(e);
        message.error(`Failed to get ${payload}`);
      } finally {
        this.updateLoading(false);
      }
    },

    async deleteAsync(payload, state): Promise<void> {
      this.updateLoading(true);
      try {
        await dispatch.mediaEntities.delete(payload);
        await this.reloadList();
        message.warning(`Item deleted`);
      } catch (e) {
        console.error(e);
        message.error(`Failed to delete`);
      } finally {
        this.updateLoading(false);
      }
    },

    async saveAsync(payload, state): Promise<FileModel | undefined> {
      this.updateLoading(true);
      try {
        const result = await dispatch.mediaEntities.save(payload);
        message.success('Save successfully');
        return result;
      } catch (err) {
        console.error(err);
        message.error('Fail to save');
      } finally {
        this.updateLoading(false);
      }
    },

    async uploadFiles(payload, state): Promise<FileModel[]> {
      this.updateLoading(true);
      try {
        const files = payload;
        if (files && !!files.length) {
          return await UploaderService.uploadFiles(files, FILE_NAME_TYPE.STORAGE).then((result) => {
            return result.filesList;
          });
        }
        message.success('Upload successfully');
        return [];
      } catch (err) {
        console.error(err);
        message.error('Fail to upload');
        return [];
      } finally {
        this.updateLoading(false);
      }
    },
  }),
});
