import { MAPS_LOCATION_TRANSPORT } from '@commons/constants';
import { DirectionType, RequestDirectionType } from '@types';

export const parseDirection = (directionData: any): DirectionType | undefined => {
  if (!directionData) {
    return undefined;
  }

  if (typeof directionData === 'string') {
    try {
      return JSON.parse(directionData);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }

  const direction = directionData as DirectionType;
  let request: RequestDirectionType | undefined = undefined;
  if (direction.request) {
    const { travelMode, destination, waypoints, origin, optimizeWaypoints } = direction.request;
    request = {
      origin,
      destination,
      travelMode,
      optimizeWaypoints,
      waypoints,
    };
  }
  return {
    ...directionData,
    request,
  };
};

export const convertMapDirectionsToDirectionData = (direction: any): DirectionType | null => {
  let directionData: any = direction;
  if (!direction) return null;

  if (typeof direction === 'string') {
    try {
      directionData = JSON.parse(direction);
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  const keys = Object.keys(directionData);
  if (keys.length === 1 && keys[0] === 'routes') {
    return directionData;
  }

  let routes: any[] = [];
  try {
    if (directionData.routes && !!directionData.routes.length) {
      routes = directionData.routes.map((route) => ({
        bounds: route.bounds,
        legs: (route?.legs || []).map((leg) => ({
          steps: (leg?.steps || []).map((step) => ({
            travel_mode: MAPS_LOCATION_TRANSPORT.DRIVING.key,
            path: convertPath(step),
            polyline: step.polyline,
          })),
          via_waypoint: leg?.via_waypoint,
          via_waypoints: leg?.via_waypoints,
          start_location: leg?.start_location,
          end_location: leg?.end_location,
        })),
      }));
    }
  } catch (e) {
    console.warn(e);
  }

  let request: RequestDirectionType | undefined = undefined;
  if (directionData.request) {
    const { travelMode, origin, destination, waypoints, optimizeWaypoints } = (
      directionData as DirectionType
    ).request!;
    request = {
      origin,
      destination,
      waypoints,
      optimizeWaypoints,
      travelMode,
    };
  }

  return {
    routes,
    request,
  };
};

const convertPath = (step: any): any => {
  if (!step) return null;

  // case direction from cms
  if (step?.polyline?.encodedPath) {
    return polylineDecoder(step.polyline.encodedPath);
  }

  // case direction from google map
  return step?.path;
};

const polylineDecoder = (encoded: string): any[] => {
  // array that holds the points

  const points: any = [];
  let index = 0;
  const len = encoded.length;
  let lat = 0;
  let lng = 0;
  while (index < len) {
    let b;
    let shift = 0;
    let result = 0;
    do {
      // finds ascii
      // and substract it by 63
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lat += dlat;
    shift = 0;
    result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    points.push({ lat: lat / 1e5, lng: lng / 1e5 });
  }
  return points;
};
