export const MAPS_ZOOM_DEFAULT_LEVEL = 14;

export enum MAP_STYLE {
  STANDARD = 'STANDARD',
  SILVER = 'SILVER',
  RETRO = 'RETRO',
  DARK = 'DARK',
  NIGHT = 'NIGHT',
  AUBERGINE = 'AUBERGINE',
}

export const DEFAULT_LOCATION = {
  // location office of HCM city
  lat: 10.775749,
  lng: 106.690864,
};

export enum DIRECTION_RENDERER {
  ONE_WAY_DIRECTION = 'ONE_WAY_DIRECTION',
  MULTI_POINTS_DIRECTION = 'MULTI_POINTS_DIRECTION',
}

export const DIRECTION_STYLE = {
  LINE: { key: 'LINE', strokeColor: '#f6475f', strokeWeight: 6, zIndex: 2 },
  DASHED: {
    key: 'DASHED',
    strokeColor: 'transparent',
    strokeWeight: 6,
    zIndex: 1,
    icons: [
      {
        icon: {
          path: 0,
          scale: 2,
          fillOpacity: 0.8,
          fillColor: '#49a8db',
          strokeOpacity: 0.8,
          strokeColor: '#49a8db',
        },
        repeat: '10px',
      },
    ],
  },
};

export const MAPS_LOCATION_TRANSPORT = {
  DRIVING: {
    key: 'DRIVING',
    variable: 'driving',
  },
  TRANSIT: {
    key: 'TRANSIT',
    variable: 'transit',
  },
  WALKING: {
    key: 'WALKING',
    variable: 'walking',
  },
};
export const PREFIX_INFO_WINDOW = 'infoWindow_';

export enum TRAVELING_MARKER_Z_INDEX {
  BUS_MARKER = 6,
  ACTIVE_MARKER = 5,
  WAIT_MARKER = 4,
}

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
export const GOOGLE_MAPS_REGION = process.env.REACT_APP_GOOGLE_MAPS_REGION || '';
export const GOOGLE_MAPS_LANGUAGE = 'vi';
