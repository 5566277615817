import { LanguageType } from '@types';

export const DEFAULT_LANG_CODE = 'VN';
export const LANGUAGE: { [key: string]: LanguageType } = {
  VN: {
    code: 'VN',
    name: 'VietNam',
    locale: 'vi_VN',
  },
  EN: {
    code: 'EN',
    name: 'English',
    locale: 'en_US',
  },
  JP: {
    code: 'JP',
    name: 'Japanese',
    locale: 'ja_JP',
  },
  KR: {
    code: 'KR',
    name: 'Korean',
    locale: 'ko_KR',
  },
  CN: {
    code: 'CN',
    name: 'Chinese',
    locale: 'zh_CN',
  },
  ES: {
    code: 'ES',
    name: 'Spanish',
    locale: 'es_ES',
  },
  DE: {
    code: 'DE',
    name: 'German',
    locale: 'de_DE',
  },
  FR: {
    code: 'FR',
    name: 'French',
    locale: 'fr_FR',
  },
  RU: {
    code: 'RU',
    name: 'Russian',
    locale: 'ru_RU',
  },
  TH: {
    code: 'TH',
    name: 'Thai',
    locale: 'th_TH',
  },
};
