/* eslint-disable camelcase */
import { DirectionType, LatLngType, LocationDtoType, RouteDataType } from '@types';
import { convertMapDirectionsToDirectionData } from './directionUtils';

export const getDistanceBetween2Points = (origin: LatLngType, target: LatLngType) => {
  return Math.sqrt((target.lat! - origin.lat) ** 2 + (target.lng! - origin.lng) ** 2);
};

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

// crow flies
const getDistanceFrom2CoordsInKm = (origin: LatLngType, target: LatLngType) => {
  const { lat: lat1, lng: lon1 } = origin;
  const { lat: lat2, lng: lon2 } = target;
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // Distance in km
  return R * c;
};
export const generateTravelingRouteWithLocationIndex = (
  direction: string | DirectionType,
  waypoints?: LocationDtoType[]
): RouteDataType[] => {
  const MIN_VELOCITY = 2; // m/s
  let directionData: DirectionType | null;
  try {
    directionData = convertMapDirectionsToDirectionData(direction);
  } catch (e) {
    console.error('Failed to convert direction data', e);
    return [];
  }

  if (!directionData) {
    return [];
  }

  const leg = directionData.routes?.[0]?.legs?.[0];
  if (!leg) {
    return [];
  }

  const stepMap = {}; // for duplicated check
  const { steps = [], start_location, end_location, via_waypoint } = leg;
  let viaWaypoints = via_waypoint;
  if (waypoints?.length && waypoints.length !== via_waypoint.length) {
    const valid: any = [];
    const wps = [...via_waypoint];
    waypoints.forEach((wp) => {
      let idx = 0;
      const nearest = wps.reduce((previousValue, currentValue, currentIndex) => {
        const prevDist = Math.sqrt(
          (wp.lat! - previousValue.location.lat) ** 2 + (wp.lng! - previousValue.location.lng) ** 2
        );
        const currDist = Math.sqrt(
          (wp.lat! - currentValue.location.lat) ** 2 + (wp.lng! - currentValue.location.lng) ** 2
        );
        if (prevDist <= currDist) {
          return previousValue;
        }

        idx = currentIndex;
        return currentValue;
      }, wps[0]);

      valid.push(nearest);
      wps.splice(0, idx + 1);
    });

    viaWaypoints = valid;
  }

  const stepIdxToLocationIndexesMap: { [stepIdx: number]: number[] } = viaWaypoints.reduce(
    (m, { step_index }, waypointIndex) => {
      const locationIdx = waypointIndex + 1;
      if (m[step_index]) {
        m[step_index].push(locationIdx);
      } else {
        m[step_index] = [locationIdx];
      }
      return m;
    },
    {}
  );

  const paths: RouteDataType[] = [
    {
      locationIndex: 0,
      ...start_location,
    },
  ];

  let lastStep = steps[steps.length - 1].path;
  steps.forEach(({ path = [] }, stepIndex) => {
    const last = stepIndex === steps.length - 1;
    const locationIndexes = stepIdxToLocationIndexesMap[stepIndex];
    if (!locationIndexes) {
      if (last) {
        return;
      }

      paths.push(...path);
      return;
    }

    if (locationIndexes.length === 1) {
      const locationIndex = stepIdxToLocationIndexesMap[stepIndex][0];
      if (!last) {
        path.forEach(({ lng, lat }) => {
          paths.push({
            locationIndex,
            lng,
            lat,
          });
        });
      } else {
        const waypoint = viaWaypoints[locationIndex - 1];
        const { location } = waypoint;
        const pth = [...path];
        const nearestIdx = path.reduce((prevIdx, currentPath, currentIdx) => {
          const prevDist = getDistanceBetween2Points(path[prevIdx], location);
          const currDist = getDistanceBetween2Points(currentPath, location);
          if (prevDist <= currDist) {
            return prevIdx;
          }

          return currentIdx;
        }, 0);

        const pths = pth.splice(0, nearestIdx + 1);
        pths.forEach(({ lng, lat }) => {
          paths.push({ locationIndex, lng, lat });
        });

        lastStep = pth;
      }
    } else {
      const pth = [...path];
      locationIndexes.forEach((locationIndex) => {
        const waypoint = viaWaypoints[locationIndex - 1];
        const { location } = waypoint;
        const nearestIdx = pth.reduce((prevIdx, currentPath, currentIdx) => {
          const prevDist = getDistanceBetween2Points(pth[prevIdx], location);
          const currDist = getDistanceBetween2Points(currentPath, location);
          if (prevDist <= currDist) {
            return prevIdx;
          }

          return currentIdx;
        }, 0);

        const pths = pth.splice(0, nearestIdx + 1);
        pths.forEach(({ lng, lat }) => {
          paths.push({ locationIndex, lng, lat });
        });
      });

      if (pth.length) {
        if (last) {
          lastStep = pth;
        } else {
          paths.push(...pth);
        }
      }
    }
  });

  // handle end_location
  lastStep.forEach(({ lng, lat }) => {
    paths.push({ locationIndex: viaWaypoints.length + 1, lng, lat });
  });

  paths.push({
    locationIndex: viaWaypoints.length + 1,
    lng: end_location.lng,
    lat: end_location.lat,
  });

  const newSteps: RouteDataType[] = [];
  paths.forEach((path, idx) => {
    const key = `${path.lat}-${path.lng}`;
    if (!stepMap[key]) {
      newSteps.push(path);
      stepMap[key] = key;
    }

    if (idx === paths.length - 1) {
      return;
    }

    const nextPath = paths[idx + 1];
    const totalDistance = getDistanceFrom2CoordsInKm(path, nextPath) * 1000; // convert to M

    // distance to short to add more step
    if (totalDistance < MIN_VELOCITY) {
      return;
    }

    // number of new steps to be added, minus last step
    const numSteps = Math.ceil(totalDistance / MIN_VELOCITY) - 1;
    if (numSteps <= 0) {
      return;
    }

    const vector: LatLngType = {
      lat: nextPath.lat - path.lat,
      lng: nextPath.lng - path.lng,
    };
    const magnitude = Math.sqrt(vector.lat ** 2 + vector.lng ** 2);
    const normal: LatLngType = {
      lat: vector.lat / magnitude,
      lng: vector.lng / magnitude,
    };
    const unitMag = magnitude / numSteps;

    for (let i = 1; i <= numSteps; i++) {
      const nextMagnitude = i * unitMag;
      const lat = normal.lat * nextMagnitude + path.lat;
      const lng = normal.lng * nextMagnitude + path.lng;

      const newKey = `${lat}-${lng}`;
      const newStep: RouteDataType = {
        lat,
        lng,
        locationIndex: nextPath.locationIndex,
      };
      newSteps.push(newStep);
      stepMap[newKey] = newKey;
    }
  });

  return newSteps;
};
