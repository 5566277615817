import { Dispatch } from '@redux/store';
import { createModel } from '@rematch/core';
import { UserTravelingService } from '@services';
import { PaginationType, RootModel, UserTravelingDtoType } from '@types';
import baseEntity from './base.entity';

interface UserTravelingEntitiesState {
  ids: { [id: number]: UserTravelingDtoType };
}

const initialState: UserTravelingEntitiesState = {
  ids: {},
};

export const userTravelingEntities = createModel<RootModel>()({
  state: initialState,
  reducers: {
    ...baseEntity,
  },
  effects: (dispatch: Dispatch) => ({
    async load(payload): Promise<{ content: UserTravelingDtoType[] } & PaginationType> {
      const res = await UserTravelingService.filterUserTravelings(payload);
      const { content } = res.data;
      if (content && content.length) {
        this.saveSimplified(content);
      }
      return res.data;
    },
    async create(payload: { travelingId: number; userId: number }) {
      const { data } = await UserTravelingService.createUserTraveling({
        traveling: { id: payload.travelingId },
        user: { id: payload.userId },
      });
      this.saves([data]);
      return data;
    },
    async delete(id: number) {
      await UserTravelingService.deleteUserTraveling(id);
      this.remove({ id });
    },
  }),
});
