import { RootState } from '@redux/store';
import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

const addLocation = connect((state: RootState) => ({
  location: (state.router as any).location,
}));

export const ConnectedSwitch: any = addLocation((props: any) => <Switch {...props} />);
export const ConnectedRoute: ComponentType = addLocation((props: any) => <Route {...props} />);
